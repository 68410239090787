<template>
  <q-header
    v-if="showHeaderToolbar"
    :dark="$q.dark.isActive"
    class="absolute bg-transparent p-3"
  >
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-2">
        <menu-button />

        <div class="flex flex-col gap-2">
          <TransitionIn>
            <h2
              v-if="$route.meta.showOnNav"
              :key="$route.name || ''"
              :data-index="2"
              class="m-0 hidden p-0 font-medium leading-none tracking-wide md:block md:text-lg lg:text-xl"
            >
              {{ $route.meta?.description?.content || '' }}
            </h2>
          </TransitionIn>

          <fr-button
            v-if="viewAsUser"
            variant="outline"
            icon="fas fa-eye"
            @click="viewAsUser = null"
          >
            <q-tooltip> Stop viewing as user </q-tooltip>

            {{ viewAsUser?.email?.split('@')[0] || '' }}
          </fr-button>
        </div>
      </div>

      <user-menu-button />
    </div>
  </q-header>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import MenuButton from './menu-button-component.vue'
import UserMenuButton from './user-menu-button-component.vue'
import { useAdminStore } from '~/features/admin/store'

const authStore = useAuthStore()
const adminStore = useAdminStore()

const showHeaderToolbar = computed(() => {
  return authStore.showHeaderToolbar
})

const viewAsUser = computed({
  get: () => adminStore.viewAsUser,
  set: (value) => (adminStore.viewAsUser = value)
})
</script>
